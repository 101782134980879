.home {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.headerContainer {
  width: 50%;
  align-self: flex-end;
  margin-left: 50px;
}

.headerContainer h1 {
  justify-content: center;
  align-items: center;
  font-size: 90px;
  height: 60px;
  font-weight: 50;
  color: black;
}

.headerContainer p {
  padding: 0;
  font-size: 40px;
  font-weight: lighter;
  color: black;
}

.headerContainer button {
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  justify-content: center;
  background-color: #121619;
  height: 60px;
  width: 190px;
  color: white;
  text-decoration: none;
  font-size: 15px;
}

.headerContainer button:hover {
  background-color: #07090a;
  cursor: pointer;
  transition: 0.3s ease-in;
}

@media only screen and (max-width: 650px) {
  .home {
    justify-content: center;
    align-items: center;
  }

  .headerContainer {
    margin-left: 0px;
    border-radius: 10px;
    padding: 20px;
    width: 80vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgb(0, 0, 0, 0.5);
    color: white;
    align-self: center;
  }

  .headerContainer h2 {
    font-size: 30px;
    height: 30px;
    color: white;
  }

  .headerContainer p {
    font-size: 30px;
    color: white;
  }

  .headerContainer button {
    background-color: white;
    color: #121619;
    margin-bottom: 30px;
  }

  .headerContainer button:hover {
    background-color: rgb(225, 225, 225);
    color: #121619;
  }
}
