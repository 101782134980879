.App {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #18a0d0;
}

body {
  padding: 0;
  margin: 0;
}

.link {
  text-decoration: inherit;
}