.services {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.service .serviceTitle {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 60px;
}

.serviceList {
  width: 70vw;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  place-items: center;
}

/* SERVICE ITEM STYLING */

.serviceItem {
  border-radius: 15px;
  width: 300px;
  height: 350px;
  margin: 20px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  background-color: black;
  display: flex;
  flex-direction: column;
}
.serviceItem:hover {
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
  transition: 0.3s ease-in;
  cursor: pointer;
}

.serviceItem div {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.serviceItem > p {
  color: white;
  font-size: 20px;
  text-align: center;
}

.serviceItem p:nth-child(3) {
  transition-duration: 0.4s;
  place-items: center;
  font-style: bold;
  background-color: #EFCB54;
  color: black;
  padding: 15px 32px;
  border-radius: 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  align-self: center;
}

.serviceItem p:nth-child(3):hover {
  background-color: #FDFF00;
  color: black;
}


@media only screen and (max-width: 1300px) {
  .serviceList {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 800px) {
  .serviceList {
    grid-template-columns: 1fr;
    width: 100vw
  }

  .serviceItem {
    margin: 20px 0px;
  }

}
