.contact {
  width: 100vw;
  height: 90vh;
  display: flex;
}

.contact .leftSide {
  height: 100%;
  flex: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.contact .rightSide {
  height: 100%;
  flex: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact .rightSide h1 {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-weight: 10;
  font-size: 50px;

}

.contact .rightSide {
  padding: 30px;
}

#contact-form {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
}

#contact-form input {
  margin-top: 15px;
  height: 40px;
  width: 80%;
  border: none;
  
  color: black;
}

#contact-form textarea {
  margin-top: 15px;
  height: 70px;
  width: 80%;
  border: none;
  
  color: black;
  padding: 5px 0;
}
input::placeholder,
textarea::placeholder {
  font-weight: bold;
  color: grey;
  font-family: Arial, Helvetica, sans-serif;
  padding: 5px;
}
#contact-form input:focus,
textarea:focus {
  outline: none;
}

#contact-form label {
  margin-top: 15px;
  color: black;
}

#contact-form button {
  margin-top: 40px;
  width: 140px;
  height: 40px;
  border: none;
  background-color: #121619;
  color: whitesmoke;
  font-size: 15px;
  cursor: pointer;
}


@media only screen and (max-width: 650px) {
  .contact {
    flex-direction: column;
  }

  .contact .leftSide  {
    height: 200px;
  }
  .contact .rightSide  {
    padding: 15px;
  }

  .contact .rightSide h2 {
    margin-bottom: 50px;
  
  }
}