.dogWalks {
    width: 100%;
    height: 90vh;
    display: flex;
    align-items: center;
  }
  
  .dogWalksLeft {
    height: 100%;
    flex: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .dogWalksRight {
    height: 100%;
    flex: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px;
  }
  
  .dogWalksMiddle {
    border: 2px solid white;
    padding: 30px 50px;
    border-radius: 10px;
    z-index: 0;
  }
  
  .dogWalksRight p {
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    font-weight: 10;
  }
  
  .dogWalks .dogWalksRight h1,
  h2 {
    font-weight: 400;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    color: black;
    height: 30px;
    display: flex;
    justify-content: center;
  }
  
  @media only screen and (max-width: 650px) {
    .dogWalks {
      flex-direction: column;
      height: auto;
      display: block
    }
  
    .dogWalksLeft  {
      height: 30vh;
      width: 100vw;
      display: block
    }
  
    .dogWalksRight  {
      padding: 15px;
    }
  
    .dogWalksMiddle {
      border: none;
    }
  }



  